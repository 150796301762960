import { TextSelection } from "./types";

const NO_TEXT_SELECTION: TextSelection = {
  text: "",
  coordinates: {
    left: 0,
    top: 0,
  },
  fragmentId: null,
};

export { NO_TEXT_SELECTION };
