import Chart from "react-apexcharts";
import { useChartConfig } from "./useChartConfig";
import { CHART_TYPES, ThreeDimensionalScatterChartKpcAggregated } from "views/SurveysResultsView/api/types";
import { x } from "@xstyled/styled-components";
import { Pill, useThemeTokens } from "@alphasights/alphadesign-components";
import { LabeledIcon } from "pages/AlphaNowPage/primers/components";
import { Info } from "@alphasights/alphadesign-icons";

export type ThreeDimensionalKpcChartProps = {
  results: ThreeDimensionalScatterChartKpcAggregated[];
  selectedChartSegment: number;
  setSelectedChartSegment: (index: number) => void;
  chartType: CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC | CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT;
};

const ThreeDimensionalKpcChart = ({
  results,
  selectedChartSegment,
  setSelectedChartSegment,
  chartType,
}: ThreeDimensionalKpcChartProps) => {
  const { vendors, values, config } = useChartConfig({
    results,
    selectedChartSegment,
    setSelectedChartSegment,
    chartType,
  });
  const { color } = useThemeTokens();

  return (
    <x.div display="flex" flexDirection="column" gap={4}>
      <Pill variant="outline" w="fit-content" pointerEvents="none">
        {`N = ${vendors}`}
      </Pill>
      <x.div display="flex" flexDirection="column" gap={4}>
        <LabeledIcon
          labelColor={color.text.assistive}
          iconColor={color.text.assistive}
          labelText="Click a line to see a vendor’s KPC ratings breakdown"
          iconItem={<Info />}
        />
      </x.div>
      <Chart
        options={config as ApexCharts.ApexOptions}
        series={values as ApexAxisChartSeries}
        type="line"
        height={300}
        width="100%"
      />
    </x.div>
  );
};
export default ThreeDimensionalKpcChart;
