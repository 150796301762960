import {
  GetRelevantCompaniesResponse,
  interactions as interactionsApi,
  RelevantCompany,
} from "@alphasights/portal-api-client";
import { useQuery } from "@tanstack/react-query";

export function useRelevantCompanies(projectToken: string, interactionId: string, enabled = true) {
  const query = useQuery<GetRelevantCompaniesResponse>({
    queryKey: ["relevantCompanies", projectToken, interactionId],
    queryFn: () => interactionsApi.getRelevantCompanies(projectToken, [interactionId]),
    enabled,
  });

  const data = interactionId
    ? query.data?.filter((item: RelevantCompany) => item.interactionId === interactionId)
    : query.data;

  return { ...query, data };
}
