import {
  Icon as AdsIcon,
  Tooltip,
  Typography,
  useThemeTokens,
  XStyledAndHTMLProps,
} from "@alphasights/alphadesign-components";
import { Recommended, SeniorExecutive, Translate, Verified } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { TotalHighlights } from "components/TotalHighlights";
import { forwardRef } from "react";
import { HighlightTextTypography } from "../../components/AdvisorCard/HighlightText";
import { CustomReason } from "../../components/CustomReason";
import { getLanguageFromCode } from "../../helpers/displayHelpers";
import { totalFlyoutHighlights } from "../../utils/reuseHighlights";
import { useIsConsultingClientProject } from "app/services/useProject";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useExecutivePartnershipsEligibility } from "hooks/useExecutivePartnershipsEligibility";
import { ExpertTableRow } from "models/ExpertTable";

type BadgeProps = {
  IconComponent: React.ComponentType;
  text?: string;
} & XStyledAndHTMLProps<HTMLDivElement>;

const Badge = forwardRef<HTMLDivElement, BadgeProps>(({ IconComponent, text, ...props }, ref) => {
  return (
    <x.div
      ref={ref}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="base"
      lineHeight="4"
      {...props}
    >
      {IconComponent && (
        <AdsIcon size="small">
          <IconComponent />
        </AdsIcon>
      )}
      {text}
    </x.div>
  );
});

const ExecutivePartnershipBadge = (props: Partial<BadgeProps>) => (
  <Tooltip title="Suitable for longer-term work via our Executive Partnerships team">
    <Badge IconComponent={SeniorExecutive} {...props} />
  </Tooltip>
);

const HighlyRecommendedBadge = ({
  data: { highlyRecommendedReason, primaryContact },
  ...props
}: {
  data: Interaction;
} & Partial<BadgeProps>) => {
  const hasCustomReason = highlyRecommendedReason && primaryContact;

  return (
    <Tooltip
      title={
        hasCustomReason ? (
          <CustomReason reason={highlyRecommendedReason} primaryContact={primaryContact} />
        ) : (
          "This expert is highly recommended."
        )
      }
    >
      <Badge IconComponent={Recommended} {...props} />
    </Tooltip>
  );
};

const NeedsInterpreterBadge = ({
  needsInterpreter,
  ...props
}: {
  needsInterpreter: string;
} & Partial<BadgeProps>) => (
  <Tooltip title={`Needs Interpreter: ${getLanguageFromCode(needsInterpreter)}`}>
    <Badge IconComponent={Translate} {...props} />
  </Tooltip>
);

const VerifiedBadge = (props: Partial<BadgeProps>) => {
  const { color } = useThemeTokens();

  return (
    <Tooltip title="This advisor has completed multiple successful interactions with your colleagues over the past 12 months">
      <Badge style={{ color: color.icon.strong._ }} IconComponent={Verified} {...props} />
    </Tooltip>
  );
};

export const AdvisorColumn = ({ row: { original: data } }: { row: ExpertTableRow }) => {
  const { color, spacing } = useThemeTokens();
  const { project } = useCurrentProjectContext();

  const isConsultingClient = useIsConsultingClientProject(data.projectToken);

  const colorClass = isConsultingClient ? color.text.info : color.text.strong._;
  const textVariant = isConsultingClient ? "body-small" : "body-small-em";

  const textClass = data.hidden ? color.text.secondary : colorClass;
  const totalHighlights = totalFlyoutHighlights(data.highlights || []);
  const isNameNotWithheld = data.advisorName !== "[advisor name withheld]";
  const { isEligible: isEligibleForExecutivePartnership } = useExecutivePartnershipsEligibility({
    projectToken: project?.token,
    interactionId: data.id,
  });

  return (
    <x.div
      data-testid={`advisor-row-name-${data.id}`}
      display="flex"
      hidden={data.hidden}
      overflow={"hidden"}
      justifyContent={"space-between"}
    >
      {isNameNotWithheld ? (
        <HighlightTextTypography
          variant={textVariant}
          component="span"
          highlights={data.highlights}
          fieldNames={[
            "advisorships.advisor_name",
            "advisorships.advisor_name.concat",
            "advisor.name",
            "advisor.name.concat",
          ]}
          text={data.advisorName}
          color={textClass}
          preventLineBreak
        />
      ) : (
        <Typography
          variant="body-small"
          component="span"
          color={textClass}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {data.advisorName}
        </Typography>
      )}

      <x.div display="flex" gap={spacing.layout.base} flexShrink={0}>
        {isEligibleForExecutivePartnership && (
          <ExecutivePartnershipBadge data-testid={`advisor-row-executive-${data.id}`} />
        )}
        {data.isActiveProject && data.verified && <VerifiedBadge data-testid={`advisor-row-verified-${data.id}`} />}
        {data.needsInterpreter && (
          <NeedsInterpreterBadge
            needsInterpreter={data.needsInterpreter}
            data-testid={`advisor-row-interpreter-${data.id}`}
          />
        )}
        {data.highlyRecommended && (
          <HighlyRecommendedBadge data={data} data-testid={`advisor-row-recommended-${data.id}`} />
        )}
        {totalHighlights > 0 && (
          <TotalHighlights totalHighlights={totalHighlights} data-testid={`advisor-row-total-highlights-${data.id}`} />
        )}
      </x.div>
    </x.div>
  );
};
