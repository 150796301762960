import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

type WrapperProps = {
  shouldHideCopyButton: boolean;
};

export const Wrapper = styled.div(({ shouldHideCopyButton }: WrapperProps) => {
  const { spacing, shape, color } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as const,
    padding: spacing.inner.base04,
    borderRadius: shape.border.radius.mediumLarge,
    "&:hover": {
      backgroundColor: shouldHideCopyButton ? undefined : color.background.surface.page.hover,
      cursor: shouldHideCopyButton ? undefined : "pointer",
    },
  };
});

export const SpeakerWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  return {
    display: "flex",
    flexDirection: "row" as const,
    marginBottom: spacing.inner.base,
    gap: isMobile ? spacing.inner.base04 : spacing.inner.base06,
  };
});

export const SpeakerInfoWrapper = styled.div(() => {
  return {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
});
