import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { offset } from "@floating-ui/react-dom";
import { x } from "@xstyled/styled-components";
import { useAlphaToast, useThemeTokens } from "@alphasights/alphadesign-components";
import { useAppSearchContext } from "providers/AppSearchProvider";
import ConversationFragment from "./ConversationFragment/ConversationFragment";
import { AlphaNowSpinner } from "pages/AlphaNowPage/components";
import { SPEAKER } from "constants/AlphaNow";
import { NO_TEXT_SELECTION } from "./consts";
import {
  COPY_QUOTE_SUCCESS_MESSAGE,
  COPY_QUOTE_ERROR_MESSAGE,
} from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView/consts";
import { eventOnElement } from "utils";
import CopyQuoteButton from "components/CopyQuoteButton";
import { copyQuoteToClipboard } from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView/utils";
import { getSpeakerInfo, getSelectedTextInElement, getTextSelectionCoordinates } from "./utils";
import { TConversationFragment, TextSelection } from "./types";

import * as S from "./Conversation.styled";

export type ConversationProps = {
  addRefToMention: (ref: React.RefObject<HTMLSpanElement>) => void;
  conversation: TConversationFragment[];
  speakers: Speaker[];
  contentType: string;
};

const Conversation = ({ addRefToMention, conversation, speakers, contentType }: ConversationProps) => {
  const { spacing } = useThemeTokens();
  const {
    query: { fragmentIds },
  } = useAppSearchContext();
  const { toast } = useAlphaToast();
  // do not change speakerMap default value to {} as it will introduce a bug
  // where speaker company & title will briefly be displayed as undefined
  const [speakerMap, setSpeakerMap] = useState<Record<string, any>>({});
  const [isFragmentActive, setFragmentActive] = useState(false);
  const [textSelection, setTextSelection] = useState(NO_TEXT_SELECTION);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const fragmentRefs = useRef<Record<number, HTMLDivElement>>({});
  // this is used for event listeners for text selection
  const textSelectionRef = useRef(textSelection);
  const copySelectionPopoverRef = useRef<HTMLDivElement>(null);

  const hasTextSelection = textSelection.text.length > 0;
  const showCopySelectionButton = hasTextSelection;

  const updateTextSelection = useCallback((newTextSelection: TextSelection) => {
    textSelectionRef.current = newTextSelection;
    setTextSelection(newTextSelection);
  }, []);

  const handleSelectionChange = useCallback(
    (fragmentId: number, element: HTMLElement) => {
      setPopoverAnchorEl(element);
      const selectedText = getSelectedTextInElement(element);
      if (selectedText.length > 0) {
        const coordinates = getTextSelectionCoordinates();
        updateTextSelection({
          fragmentId,
          text: selectedText,
          coordinates,
        });
      } else {
        updateTextSelection(NO_TEXT_SELECTION);
        setPopoverAnchorEl(null);
      }
    },
    [updateTextSelection]
  );

  const handleCopyQuote = useCallback(async () => {
    const fragment = conversation[textSelection.fragmentId!];
    const speakerInfo = getSpeakerInfo(fragment, speakerMap);
    try {
      await copyQuoteToClipboard(textSelection.text, speakerInfo.company, speakerInfo.jobTitle);
      toast.success({ message: COPY_QUOTE_SUCCESS_MESSAGE });
    } catch (err) {
      console.error(COPY_QUOTE_ERROR_MESSAGE, err);
    }
  }, [speakerMap, textSelection, conversation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFragmentActive && fragmentIds.length > 0) {
      const timer = setTimeout(() => {
        setFragmentActive(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isFragmentActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fragmentIds.length > 0 && setFragmentActive(true);
  }, [fragmentIds]);

  useEffect(() => {
    const speakersInfo = speakers.reduce<Record<string, any>>((speakerMap, speaker) => {
      const { company, jobTitle, transcriptParticipantRole, isModerator } = speaker;
      speakerMap[speaker.transcriptParticipantRole] = {
        company,
        jobTitle,
        transcriptParticipantRole,
        isModerator,
      };
      return speakerMap;
    }, {});
    setSpeakerMap(speakersInfo);
  }, [conversation, speakers]);

  useEffect(() => {
    if (fragmentIds.length > 0) {
      // We are only navigating to the first fragment on the list for now
      const refId = Number(fragmentIds[0]);
      const componentRef = fragmentRefs.current[refId];
      if (componentRef) {
        componentRef.scrollIntoView({ behavior: "smooth" });
      }
    }
  });

  useEffect(() => {
    const handleSelection = (event: MouseEvent) => {
      if (eventOnElement(event, copySelectionPopoverRef.current)) return;

      const fragmentElement = (event.target as HTMLElement).closest("[data-fragmentid]") as HTMLElement;
      if (eventOnElement(event, fragmentElement)) {
        const fragmentId = Number(fragmentElement.dataset?.fragmentid);
        handleSelectionChange(fragmentId, fragmentElement);
      } else if (textSelectionRef.current.text.length > 0) {
        setPopoverAnchorEl(null);
      }
    };
    document.addEventListener("mouseup", handleSelection);
    return () => {
      document.removeEventListener("mouseup", handleSelection);
    };
  }, [handleSelectionChange, updateTextSelection]);

  const copySelectionButtonPopover = useMemo(() => {
    const fragmentRef = fragmentRefs!.current[textSelection.fragmentId!]?.getBoundingClientRect() ?? {};
    const buttonOffset = fragmentRef
      ? {
          mainAxis: textSelection.coordinates.top! - fragmentRef.bottom,
          crossAxis: textSelection.coordinates.left! - fragmentRef.left,
        }
      : {};

    return (
      <S.StyledCopySelectionButtonPopover
        ref={copySelectionPopoverRef}
        anchorEl={popoverAnchorEl as Element | undefined}
        size="small"
        open
        isVisible={Boolean(popoverAnchorEl)}
        hasFloatingVisibility
        portalEl={ref?.current as HTMLElement}
        middleware={[offset(buttonOffset)]}
        zIndex={10}
        onClick={handleCopyQuote}
      >
        <CopyQuoteButton
          variant="ghost"
          id="copy-selection-button"
          styles={{
            margin: "0 auto",
            left: "-1px",
            top: "-1px",
            width: spacing.inner.base06,
            height: spacing.inner.base06,
          }}
        ></CopyQuoteButton>
      </S.StyledCopySelectionButtonPopover>
    );
  }, [popoverAnchorEl, textSelection, ref, spacing, handleCopyQuote]);

  if (typeof speakerMap === "undefined") {
    return <AlphaNowSpinner />;
  }
  return (
    <S.ConversationWrapper
      ref={ref}
      ml={{ xs: spacing.layout.base03, sm: 0 }}
      mr={{ xs: spacing.layout.base02, sm: 0 }}
    >
      {conversation.map((fragment) => {
        const { position, participant, speechContent, isSensitive } = fragment;
        const speakerInfo = getSpeakerInfo(fragment, speakerMap);
        if (speakerInfo.transcriptParticipantRole === SPEAKER.Interviewer) {
          speakerInfo.transcriptParticipantRole = participant;
        }

        const isFragmentRelatedToQuestion = fragmentIds[0] === position;
        const isFragmentHighlighted = isFragmentActive && isFragmentRelatedToQuestion;

        return (
          <x.div
            key={position}
            data-testid={`conv-fragment-${position}`}
            data-fragmentid={position}
            className={isSensitive ? "obfuscated-sensitive-data" : ""}
            ref={(ref) => (fragmentRefs.current[position] = ref as HTMLDivElement)}
          >
            <ConversationFragment
              addRefToMention={addRefToMention}
              isFragmentHighlighted={isFragmentHighlighted}
              speakerInfo={speakerInfo}
              text={speechContent}
              contentType={contentType}
              shouldHideCopyButton={showCopySelectionButton}
            />
          </x.div>
        );
      })}
      {copySelectionButtonPopover}
    </S.ConversationWrapper>
  );
};

Conversation.defaultProps = {
  conversation: [],
  speakers: [],
};

Conversation.propTypes = {
  addRefToMention: PropTypes.func.isRequired,
  conversation: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      participant: PropTypes.string,
      speechContent: PropTypes.string,
      speakerDummyName: PropTypes.string,
    })
  ).isRequired,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      jobTitle: PropTypes.string,
      transcriptParticipantRole: PropTypes.string,
      isModerator: PropTypes.bool,
    })
  ).isRequired,
};

export default Conversation;
