import { useRef, useState, useMemo, useEffect } from "react";
import * as React from "react";
import {
  Icon,
  RoundedButton,
  ListOption as AdsListOption,
  Popover,
  Button,
  Slider,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";
import useDeliverablesFiltersStyles from "./DeliverablesFilters.styles";
import { SORT_LABELS } from "../helpers";
import { SortAZ } from "@alphasights/alphadesign-icons";
import { FilterChip } from "components/InteractionsPage/FilterChip";
import { flatten, noop, omit, pick } from "lodash";
import { filterDefinitions } from "./filterDefinitions";
import { ButtonWithMargin } from "@alphasights/client-portal-shared";
import { FilterItemOptions } from "components/InteractionsPage/FilterItemOptions";
import { useCurrentUser } from "@alphasights/portal-auth-react";

type AppliedFilters = {
  [key: string]: string[];
};

export type DeliverablesFiltersProps = {
  appliedFilters: AppliedFilters;
  options: {
    groups: FilterOption[];
    alphaNowTypesSet?: string[];
  };
  sortCriteria: string;
  onSubmitFilters: (filters: AppliedFilters) => void;
  onSortBy: (sortBy: string) => void;
  transcriptTypesSet?: Set<string>;
  loading: boolean;
  hasAppliedKeyword?: boolean;
  hasUploadedFilter?: boolean;
};

export const DeliverablesFilters = ({
  appliedFilters,
  options,
  sortCriteria,
  onSubmitFilters,
  onSortBy,
  transcriptTypesSet = new Set(),
  loading,
  hasAppliedKeyword,
  hasUploadedFilter,
}: DeliverablesFiltersProps) => {
  const styles = useDeliverablesFiltersStyles();
  const currentUser = useCurrentUser();
  const deliverablesFilterDefinitions = filterDefinitions({
    options,
    appliedFilters,
    transcriptTypesSet,
    aiSynthesisOnly: currentUser?.aiSynthesisOnly,
    hasUploadedFilter,
  });

  return (
    <x.div data-testid="deliverables-filter" {...styles.filters}>
      <x.div data-testid="deliverables-filter-buttons" {...(loading && styles.noHoverNoClick)}>
        <CollapsedFilters
          filterDefinitions={deliverablesFilterDefinitions}
          onSubmitFilters={onSubmitFilters}
          appliedFilters={appliedFilters}
          hasAppliedKeyword={hasAppliedKeyword}
        />
      </x.div>

      <x.div data-testid="deliverables-filter-right" {...styles.filtersRight}>
        <SortByFilter sortBy={sortCriteria} setSortBy={onSortBy} disabled={loading} />
      </x.div>
    </x.div>
  );
};

const CollapsedFilters = ({
  filterDefinitions,
  onSubmitFilters,
  appliedFilters,
  hasAppliedKeyword,
}: {
  filterDefinitions: FilterDefinition[];
  onSubmitFilters: (filters: AppliedFilters) => void;
  appliedFilters: AppliedFilters;
  hasAppliedKeyword?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const sliderFiltersApplies = pick(
    appliedFilters,
    filterDefinitions.filter((f) => f.type === "slider").map((f) => f.name)
  );
  const nonSliderFiltersApplies = omit(
    appliedFilters,
    filterDefinitions.filter((f) => f.type === "slider").map((f) => f.name)
  );

  return (
    <x.div ref={ref}>
      <FilterChip
        title="Filters"
        onClick={() => setIsOpen((prev) => !prev)}
        appliedFilters={flatten(
          Object.values(nonSliderFiltersApplies).concat(Object.values(sliderFiltersApplies).map((value) => value[0]))
        )}
        open={isOpen}
      />
      <FiltersPopover
        anchorEl={ref.current ?? undefined}
        onClose={() => setIsOpen(false)}
        filterDefinitions={filterDefinitions}
        onSubmitFilters={onSubmitFilters}
        appliedFilters={appliedFilters}
        open={isOpen}
        placement={hasAppliedKeyword ? "bottom-end" : "bottom-start"}
      />
    </x.div>
  );
};

const SortByFilter = ({
  sortBy,
  setSortBy,
  disabled,
}: {
  sortBy: string;
  setSortBy: (value: string) => void;
  disabled: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <x.div ref={ref}>
      <SortByFilterButton ref={buttonRef} isOpen={isOpen} setIsOpen={setIsOpen} disabled={disabled} />
      {isOpen && (
        <SortByModal value={sortBy} setSortBy={setSortBy} anchorRef={buttonRef} onClose={() => setIsOpen(false)} />
      )}
    </x.div>
  );
};

const SortByModal = ({
  value: currentValue,
  setSortBy,
  anchorRef,
  onClose,
}: {
  value: string;
  setSortBy: (value: string) => void;
  anchorRef: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
}) => {
  return (
    <Popover
      animationFrame={true}
      portal={false}
      anchorEl={anchorRef?.current ?? undefined}
      open
      onClose={onClose}
      placement="bottom-start"
      zIndex={30}
    >
      <PopoverContainer>
        {Object.entries(SORT_LABELS).map(([value, label]) => (
          <ListOption
            key={value}
            type="text"
            label={label}
            onChange={() => setSortBy(value)}
            selected={value === currentValue}
          />
        ))}
      </PopoverContainer>
    </Popover>
  );
};

const SortByFilterButton = React.forwardRef(
  (
    {
      isOpen,
      setIsOpen,
      disabled,
    }: {
      isOpen: boolean;
      setIsOpen: (value: boolean) => void;
      disabled: boolean;
    },
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const styles = useDeliverablesFiltersStyles();

    return (
      <RoundedButton
        ref={ref}
        dataAttributes={{ "data-testid": "deliverables-filter-sort-by" }}
        {...styles.filtersSortBy}
        {...(isOpen && styles.filtersSortBySelected)}
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
        startIcon={
          <Icon color="secondary" size="small">
            <SortAZ />
          </Icon>
        }
      />
    );
  }
);

export const FiltersPopover = ({
  anchorEl,
  onClose,
  filterDefinitions,
  onSubmitFilters,
  appliedFilters,
  open,
  placement = "bottom-start",
}: {
  anchorEl?: Element;
  onClose: () => void;
  filterDefinitions: FilterDefinition[];
  onSubmitFilters: (filters: AppliedFilters) => void;
  appliedFilters: AppliedFilters;
  open: boolean;
  placement?: "bottom-start" | "bottom-end";
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selectedFilters, setSelectedFilters] = React.useState<AppliedFilters>(appliedFilters);
  const { buttonsWrapper } = useDeliverablesFiltersStyles();

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  const onSaveFilters = () => {
    onSubmitFilters(selectedFilters);
    onClose();
  };

  const onResetFilters = () => {
    setSelectedFilters({});
    onSubmitFilters({});
    onClose();
  };

  return (
    <x.div ref={ref}>
      <NoPaddingPopover
        topNavBarHeight={ref.current?.getBoundingClientRect().top ?? 0}
        animationFrame={true}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        minWidth="270px"
        placement={placement}
        data-testid="deliverables-filter-popover"
      >
        {filterDefinitions.map((definition, index) => (
          <Filter
            key={index}
            filterDefinition={definition}
            selectedItems={selectedFilters[definition.name] || []}
            setSelectedItems={(values: string[]) => {
              if (values.length) {
                setSelectedFilters((prev) => ({
                  ...prev,
                  [definition.name]: values,
                }));
              } else {
                setSelectedFilters((prev) => omit(prev, definition.name));
              }
            }}
            showDivider={index !== filterDefinitions.length - 1}
          />
        ))}
        <x.div {...buttonsWrapper}>
          <ButtonWithMargin variant="ghost" onClick={onResetFilters} data-testid="clear-filter-button">
            Reset
          </ButtonWithMargin>
          <Button ml="auto" variant="secondary" onClick={onSaveFilters} data-testid="save-filter-button">
            Show Results
          </Button>
        </x.div>
      </NoPaddingPopover>
    </x.div>
  );
};

const Filter = ({
  filterDefinition,
  selectedItems,
  setSelectedItems,
  showDivider,
}: {
  filterDefinition: FilterDefinition;
  selectedItems: string[];
  setSelectedItems: (values: string[]) => void;
  showDivider?: boolean;
}) => {
  const Component =
    filterDefinition.type === "boolean"
      ? BooleanFilter
      : filterDefinition.type === "slider"
      ? SliderFilter
      : CheckboxFilter;

  return (
    <>
      <Component
        filterDefinition={filterDefinition}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      {showDivider && <ListOption type="divider" />}
    </>
  );
};

const CheckboxFilter = ({
  filterDefinition,
  selectedItems,
  setSelectedItems,
}: {
  filterDefinition: FilterDefinition;
  selectedItems: string[];
  setSelectedItems: (values: string[]) => void;
}) => {
  return (
    <>
      <ListOption type="title" label={sentenceCase(filterDefinition.title)} />
      <FilterItemOptions
        filterOptions={filterDefinition.options}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </>
  );
};

const SliderFilter = ({
  filterDefinition,
  selectedItems,
  setSelectedItems,
}: {
  filterDefinition: FilterDefinition;
  selectedItems: string[];
  setSelectedItems: (values: string[]) => void;
}) => {
  const reversedOptions = useMemo(() => filterDefinition.options.slice().reverse(), [filterDefinition.options]);

  const marks = useMemo(
    () =>
      reversedOptions.map((option, index) => {
        const selectedIndexes = selectedItems.map((selectedItem) =>
          reversedOptions.findIndex((o) => o.value === selectedItem)
        );
        const [minIndex, maxIndex] = [Math.min(...selectedIndexes), Math.max(...selectedIndexes)];
        const minSelectedLabel = reversedOptions[minIndex]?.label ?? reversedOptions[0].label;
        const maxSelectedLabel = reversedOptions[maxIndex]?.label ?? reversedOptions[reversedOptions.length - 1].label;
        return {
          value: index,
          label: index === 0 ? minSelectedLabel : index === reversedOptions.length - 1 ? maxSelectedLabel : "",
          originalValue: option.value,
        };
      }),
    [reversedOptions, selectedItems]
  );

  const defaultValue = useMemo(() => {
    const selectedIndexes = selectedItems.map((selectedItem) =>
      marks.findIndex((mark) => mark.originalValue === selectedItem)
    );
    return [Math.min(...selectedIndexes), Math.max(...selectedIndexes)];
  }, [marks, selectedItems]);

  const handleChange = (event: Event) => {
    const [minValue, maxValue] = (event.target as HTMLInputElement)?.value;
    const newSelectedItems = marks.slice(parseInt(minValue), parseInt(maxValue) + 1).map((m) => m.originalValue);
    setSelectedItems(newSelectedItems.length === reversedOptions.length ? [] : newSelectedItems);
  };

  return (
    <>
      <ListOption type="title" label={sentenceCase(filterDefinition.title)} />
      <SliderWrapper>
        <Slider
          defaultValue={defaultValue}
          max={marks.length - 1}
          min={0}
          marks={marks}
          onChange={handleChange}
          width="230px"
        />
      </SliderWrapper>
    </>
  );
};

const BooleanFilter = ({
  filterDefinition,
  selectedItems,
  setSelectedItems,
}: {
  filterDefinition: FilterDefinition;
  selectedItems: string[];
  setSelectedItems: (values: string[]) => void;
}) => {
  const { booleanFilter } = useDeliverablesFiltersStyles();
  const onChange = ({ isSelected }: { isSelected: boolean }) => {
    setSelectedItems(!isSelected ? [] : ["true"]);
  };

  const selected = selectedItems.includes("true");

  return (
    <x.div {...booleanFilter}>
      <ListOption
        type="text"
        label={filterDefinition.title}
        selected={selected}
        rightSwitch={selected}
        rightSwitchOnClick={noop}
        onChange={onChange}
      />
    </x.div>
  );
};

const sentenceCase = (str: string) => {
  const lower = str.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

const ListOption = styled(AdsListOption)`
  [data-testid="switch-label"] {
    pointer-events: none;
  }
`;

const PopoverContainer = styled.div`
  min-width: 200px;
`;

const NoPaddingPopover = styled(Popover)(({ topNavBarHeight }: { topNavBarHeight: number }) => {
  const {
    spacing: { layout },
  } = useThemeTokens();

  return `
      max-height: calc(100vh - ${topNavBarHeight}px - ${layout.base04});
      overflow-y: auto;
      z-index: 30;

      .body-content {
        padding: 0;
      }
    `;
});

const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 55px;
  .MuiSlider-markLabel[data-index="0"] {
    transform: none !important;
    left: -6px !important;
  }
  .MuiSlider-markLabel[data-index="3"] {
    transform: none !important;
    left: unset !important;
    right: calc(var(--spacing-inner-base02) * -1) !important;
    text-wrap: nowrap !important;
  }
  .value-label {
    display: none !important;
  }
`;
