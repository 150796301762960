import { IconButton, IconButtonProps, Tooltip } from "@alphasights/alphadesign-components";
import { Copy } from "@alphasights/alphadesign-icons";
import styled from "@xstyled/styled-components";
import { COPY_QUOTE } from "./consts";

export const DataTestIds = {
  COPY_QUOTE_BUTTON: "copy-quote-button",
};

type CopyQuoteButtonProps = {
  styles?: React.CSSProperties;
  id?: string;
  testId?: string;
} & Pick<IconButtonProps, "onClick" | "variant" | "size">;

const CopyQuoteButton = ({
  styles = {},
  id = "copy-quote-button",
  variant = "outline",
  size = "small",
  testId = DataTestIds.COPY_QUOTE_BUTTON,
  ...props
}: CopyQuoteButtonProps) => (
  <Tooltip variant="dark" title={COPY_QUOTE}>
    <StyledIconButton
      variant={variant}
      size={size}
      styles={styles}
      {...props}
      dataAttributes={{
        "data-testid": testId,
      }}
    >
      <Copy id={id} />
    </StyledIconButton>
  </Tooltip>
);

const StyledIconButton = styled(IconButton)(({ styles }: { styles: React.CSSProperties }) => ({
  ...styles,
}));

export default CopyQuoteButton;
