import { useCallback, useMemo } from "react";
import * as React from "react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { x } from "@xstyled/styled-components";
import { HitAction, SynthesisModule } from "@alphasights/portal-api-client";
import { SynthesisSidebarCard } from "./SynthesisSidebarCard";
import { useStyles } from "./SynthesisSidebarContent.styles";
import { Icon, Popover, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import _ from "lodash";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { Expert, Transcript } from "@alphasights/alphadesign-icons";
import { SynthesisReprocessButton } from "../SynthesisModuleHeader/SynthesisReprocessButton";
import { ExpertCountByAngle } from "../hooks/useSynthesisExpertCount";

const DragDropContextWrapper = DragDropContext as React.ComponentType<any>;
const DroppableWrapper = Droppable as React.ComponentType<any>;
const DraggableWrapper = Draggable as React.ComponentType<any>;

export const SynthesisSidebarContent = ({ showSuggested }: { showSuggested: boolean }) => {
  const { projectSynthesis, nothingToShow } = useProjectSynthesisContext();
  const styles = useStyles();

  const visibleProjectSynthesis = useMemo(
    () =>
      projectSynthesis
        .map((angleSynthesis) => ({
          ...angleSynthesis,
          modules: angleSynthesis.modules.filter(
            (module) =>
              !(module as SynthesisModule & { hidden: boolean }).hidden &&
              module.visibility !== "HIDDEN" &&
              module.contentType !== "SUGGESTED_INQUIRY"
          ),
        }))
        .filter((angleSynthesis) => angleSynthesis.modules.length > 0),
    [projectSynthesis]
  );

  const countCustomModulesBeforeAngle = (index: number) => {
    if (index === 0) return 0;
    return visibleProjectSynthesis
      .slice(0, index)
      .reduce(
        (acc, angleTypeSynthesis) =>
          acc + _.filter(angleTypeSynthesis.modules, (module) => module.visibility === "ADDED").length,
        0
      );
  };

  return (
    <x.div overflow="auto" flexGrow="1" data-testid="synthesis-sidebar-content" {...styles.sidebarWrapper}>
      {nothingToShow ? (
        <EmptySynthesisSidebarContent />
      ) : (
        <>
          {visibleProjectSynthesis.map((ps, index) => (
            <AngleTypeWrapper
              key={ps.angleType}
              angleType={ps.angleType}
              modules={ps.modules.filter((m) => m.revisions.length)}
              showSuggested={showSuggested}
              indexCount={countCustomModulesBeforeAngle(index) + 1}
            />
          ))}
        </>
      )}
    </x.div>
  );
};

const AngleTypeWrapper = ({
  angleType,
  modules,
  showSuggested,
  indexCount,
}: {
  angleType: string;
  modules: SynthesisModule[];
  showSuggested: boolean;
  indexCount: number;
}) => {
  const { spacing } = useThemeTokens();
  const styles = useStyles();
  const { reorderModule, synthesisLogHit, angleTypes, readOnly } = useProjectSynthesisContext();
  const expertCountByAngle = angleTypes.find((angle) => angle.angleType === angleType)?.expertCountByAngle ?? [];
  const totalExpertCount = expertCountByAngle.reduce(
    (acc, item) => acc + item.count + (item.subAngles?.reduce((subAcc, subAngle) => subAcc + subAngle.count, 0) ?? 0),
    0
  );
  const moduleMap = _.groupBy(modules, "visibility");
  const addedModules = useMemo(() => moduleMap["ADDED"] ?? [], [moduleMap]);
  const suggestedModules = useMemo(() => moduleMap["SUGGESTED"] ?? [], [moduleMap]);

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;
      const {
        source: { index: originalIdx, droppableId: sourceDroppableId },
        destination: { index: targetIdx, droppableId: targetDroppableId },
      } = result;

      if (sourceDroppableId === targetDroppableId && originalIdx === targetIdx) return;

      let newAddedModules = [...addedModules];

      if (sourceDroppableId === "custom-module-droppable" && targetDroppableId === "custom-module-droppable") {
        const moved = addedModules[originalIdx];
        newAddedModules.splice(originalIdx, 1);
        newAddedModules.splice(targetIdx, 0, moved);
      }

      const newOrder = [...newAddedModules, ...suggestedModules].map((m) => m.id);
      reorderModule(angleType, newOrder);
      synthesisLogHit({
        action: HitAction.projectSynthesisReordered,
        details: {
          angleType,
          newOrder: newOrder,
        },
      });
    },
    [addedModules, suggestedModules, reorderModule, angleType, synthesisLogHit]
  );

  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
  const ref = React.useRef<HTMLDivElement>(null);
  const handlePopoverOpen = ({ currentTarget }: { currentTarget: Element }) => {
    setAnchorEl(currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };
  const open = Boolean(anchorEl) && expertCountByAngle.length > 0;

  return (showSuggested && suggestedModules.length) || addedModules.length ? (
    <x.div {...styles.angleWrapper}>
      <x.div pl={spacing.inner.base08}>
        <x.div
          {...styles.angleTypeNameWrapper}
          onMouseEnter={handlePopoverOpen}
          cursor={expertCountByAngle.length > 0 ? "pointer" : "unset"}
        >
          <x.div {...styles.angleTypeName}>
            <Typography variant="body-small-em">{angleType}</Typography>
            <Popover
              size="small"
              closeOnMouseLeave={true}
              ref={ref}
              anchorEl={anchorEl}
              open={open}
              onClose={handlePopoverClose}
              leaveDelay={100}
            >
              <AngleCounter angleType={angleType} expertCountByAngle={expertCountByAngle} />
            </Popover>
            <SynthesisReprocessButton angleTypeName={angleType} />
          </x.div>
          {totalExpertCount > 0 && (
            <x.div {...styles.expertCount} data-testid={`expert-count-${angleType}`}>
              <Typography variant="body-small">{totalExpertCount}</Typography>
              <Expert />
            </x.div>
          )}
        </x.div>
      </x.div>
      <x.div {...styles.indexedModuleWrapper}>
        <x.div {...styles.indexWrapper}>
          {[...addedModules, ...(showSuggested ? suggestedModules : [])].map((m, index) => (
            <IndexSection key={m.id} indexCount={indexCount + index} moduleVisibility={m.visibility} />
          ))}
        </x.div>
        <x.div display="flex" flexDirection="column" flexGrow={1}>
          <DragDropContextWrapper onDragEnd={!readOnly ? onDragEnd : () => {}}>
            <DroppableWrapper droppableId="custom-module-droppable" isDropDisabled={readOnly}>
              {(provided: DroppableProvided) => (
                <x.div ref={provided.innerRef} {...provided.droppableProps}>
                  {addedModules.map((m: SynthesisModule, index: number) => (
                    <ModuleSection key={m.id} showSuggested={showSuggested} module={m} index={index} />
                  ))}
                  {provided.placeholder}
                </x.div>
              )}
            </DroppableWrapper>
          </DragDropContextWrapper>
          <x.div>
            {suggestedModules.map((m: SynthesisModule, index: number) => (
              <ModuleSection key={m.id} showSuggested={showSuggested} module={m} index={index} />
            ))}
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  ) : (
    <></>
  );
};

const AngleCounter = ({
  angleType,
  expertCountByAngle,
}: {
  angleType: string;
  expertCountByAngle: ExpertCountByAngle[];
}) => {
  const { spacing } = useThemeTokens();
  const styles = useStyles();

  return (
    <x.div minWidth="200px" maxHeight="40dvh" overflow="auto" data-testid={`angle-counter-${angleType}`}>
      <Typography variant="body-small-em" color="assistive" p={spacing.inner.base02}>
        {angleType}
      </Typography>
      {expertCountByAngle.map((angle) => (
        <>
          <x.div key={angle.name} {...styles.angleCounterItem}>
            <Typography variant="body-small">{angle.name}</Typography>
            {angle.count > 0 && (
              <x.div {...styles.expertCount} data-testid={`expert-count-angle-name-${angle.name}`}>
                <Typography variant="body-small">{angle.count}</Typography>
                <Expert />
              </x.div>
            )}
          </x.div>
          {angle.subAngles?.map((subAngle) => (
            <x.div key={`${angle.name}-${subAngle.name}`} {...styles.angleCounterItem} ml={spacing.inner.base04}>
              <Typography variant="body-small">{subAngle.name}</Typography>
              <x.div {...styles.expertCount} data-testid={`expert-count-subangle-name-${subAngle.name}`}>
                <Typography variant="body-small">{subAngle.count}</Typography>
                <Expert />
              </x.div>
            </x.div>
          ))}
        </>
      ))}
    </x.div>
  );
};

const IndexSection = ({ indexCount, moduleVisibility }: { indexCount: number; moduleVisibility: string }) => {
  const styles = useStyles();

  return (
    <x.div {...styles.numerationText}>
      {moduleVisibility === "ADDED" && <Typography data-testid={`index-${indexCount}`}>{indexCount}</Typography>}
    </x.div>
  );
};

const ModuleSection = ({
  showSuggested,
  module,
  index,
}: {
  showSuggested: boolean;
  module: SynthesisModule;
  index: number;
}) => {
  const styles = useStyles();
  const { selectModule, synthesisLogHit } = useProjectSynthesisContext();
  const { contentStyle } = useHideDeliverablesContent();

  const onSelectModule = useCallback(
    (moduleToSelect: SynthesisModule) => {
      selectModule(moduleToSelect);
      synthesisLogHit({
        action: HitAction.projectSynthesisModuleSelected,
        details: {
          moduleType: moduleToSelect.contentType,
          moduleVisibility: moduleToSelect.visibility,
        },
        references: {
          moduleId: moduleToSelect.id,
        },
      });
    },
    [selectModule, synthesisLogHit]
  );

  return module.visibility === "ADDED" ? (
    <DraggableWrapper draggableId={module.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <x.div
          {...styles.moduleWrapper}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key={module.id}
          {...contentStyle}
          data-testid={`module-${module.id}-${module.visibility}`}
        >
          <x.div onClick={() => onSelectModule(module)} w="100%">
            <SynthesisSidebarCard synthesisModule={module} isDragging={snapshot.isDragging} />
          </x.div>
        </x.div>
      )}
    </DraggableWrapper>
  ) : showSuggested ? (
    <x.div
      {...styles.moduleWrapper}
      key={module.id}
      {...contentStyle}
      data-testid={`module-${module.id}-${module.visibility}`}
    >
      <x.div onClick={() => onSelectModule(module)} w="100%">
        <SynthesisSidebarCard synthesisModule={module} isDragging={false} />
      </x.div>
    </x.div>
  ) : (
    <></>
  );
};

export const EmptySynthesisSidebarContent = () => {
  const { emptyAnswersWrapper, emptyIconWrapper } = useStyles();

  return (
    <x.div {...emptyAnswersWrapper} data-testid="empty-sidebar-content">
      <x.div {...emptyIconWrapper}>
        <Icon size="small" color="secondary">
          <Transcript />
        </Icon>
      </x.div>
      <Typography variant="body-large-em" color="secondary">
        No Synthesis Modules
      </Typography>
      <Typography color="secondary">Suggested modules will appear here when transcripts are made available</Typography>
    </x.div>
  );
};
