import { useCheckScreen } from "@alphasights/ads-community-hooks";
import {
  Button,
  Icon,
  Pill,
  Skeleton,
  Tooltip,
  TooltipContent,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { Close, Label as LabelIcon } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { noop } from "lodash";
import { useLabelsContext } from "providers/LabelsProvider";
import * as React from "react";
import { LABEL_MAX_LENGTH, ManageLabelsPopover, truncateLabel } from "./ManageLabelsPopover";
import { useIsConsultingClientProject } from "app/services/useProject";

export interface InteractionLabelProps {
  interaction: Interaction;
  canAddNewLabel?: boolean;
  hideIcon?: boolean;
}

export const InteractionLabel = ({
  interaction,
  canAddNewLabel = false,
  hideIcon = false,
  ...props
}: InteractionLabelProps) => {
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const { getLabelledExpert, isLoading, labelsDisabled } = useLabelsContext();
  const { isMobile } = useCheckScreen();
  const { color } = useThemeTokens();
  const isConsultingClient = useIsConsultingClientProject(interaction.projectToken);

  const labelBgColor = isConsultingClient ? color.background.selected.default : color.background.action.outline.default;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (labelsDisabled) return;
    setOpen(!open);
  };

  const handlePopoverClose = () => setOpen(false);

  const expertId = interaction.advisorId;
  const angleId = interaction.angles[0].id;

  if (!angleId) return null;

  const labelledExpert = getLabelledExpert(expertId, angleId);

  const label = labelledExpert?.label?.text;
  const labelledBy = labelledExpert?.labelledBy?.name;

  if (!label && !canAddNewLabel) return null;

  return (
    <>
      <x.div display="flex" ref={ref} {...props}>
        {isLoading(expertId, angleId) ? (
          <Skeleton variant="noMargin" />
        ) : (
          label && (
            <Tooltip variant="dark" size="small" position="bottom">
              <TooltipContent>
                {label.length > LABEL_MAX_LENGTH && <Typography variant="body-small">{label}</Typography>}
                <Typography variant="body-small">Added by {labelledBy}</Typography>
              </TooltipContent>
              <div data-testid="label">
                <Label
                  labelledExpert={labelledExpert!}
                  onLabelClick={handleClick}
                  hideIcon={hideIcon}
                  selected={open}
                  disabled={labelsDisabled}
                  bgColor={labelBgColor}
                />
              </div>
            </Tooltip>
          )
        )}
      </x.div>
      {open && !isMobile && (
        <ManageLabelsPopover anchorRef={ref} open onClose={handlePopoverClose} interactions={[interaction]} />
      )}
    </>
  );
};

export const LabelButton = ({
  interactions,
  onClosePopover = noop,
}: {
  interactions: Interaction[];
  onClosePopover?: () => void;
}) => {
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    open ? closePopover() : setOpen(true);
  };

  const closePopover = () => {
    setOpen(false);
    onClosePopover();
  };

  return (
    <>
      <x.div display="flex" ref={ref}>
        <ButtonWithLoginWall data-testid="label-button" variant="ghost" startIcon={<LabelIcon />} onClick={handleClick}>
          Add Label
        </ButtonWithLoginWall>
      </x.div>
      {open && <ManageLabelsPopover anchorRef={ref} open onClose={closePopover} interactions={interactions} />}
    </>
  );
};

const Label = ({
  labelledExpert,
  onLabelClick,
  hideIcon,
  selected = false,
  disabled,
  bgColor,
}: {
  labelledExpert: LabelledExpert;
  onLabelClick: (event: React.MouseEvent) => void;
  hideIcon: boolean;
  selected?: boolean;
  disabled: boolean;
  bgColor?: string;
}) => {
  const { removeLabel } = useLabelsContext();
  const { isMobile } = useCheckScreen();
  const { color } = useThemeTokens();

  const onClickRemoveLabel = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (disabled) return;
    removeLabel(labelledExpert.id, labelledExpert.expertId, labelledExpert.angleId);
  };

  return (
    <>
      <PillWithLoginWall
        bg={`${bgColor || color.background.selected.default} !important`}
        size="small"
        onClick={onLabelClick}
        data-testid={`label-${labelledExpert.label.id}`}
        selected={!isMobile && selected}
        leftAccessories={
          hideIcon ? undefined : (
            <Icon size="medium" color="secondary">
              <LabelIcon />
            </Icon>
          )
        }
        isInteractive={false}
      >
        <x.div display="flex" gap="4px">
          <Typography variant="body-small" color="strong">
            {truncateLabel(labelledExpert.label.text)}
          </Typography>
          {!isMobile && !disabled && (
            <IconWithLoginWall
              size="small"
              color="secondary"
              data-testid="delete-label-button"
              onClick={onClickRemoveLabel}
            >
              <Close />
            </IconWithLoginWall>
          )}
        </x.div>
      </PillWithLoginWall>
    </>
  );
};

const PillWithLoginWall = withLoginWall(Pill);
const IconWithLoginWall = withLoginWall(Icon);
const ButtonWithLoginWall = withLoginWall(Button);
