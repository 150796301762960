import { AccordionItem, Alert, Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { projects } from "@alphasights/portal-api-client";
import { usePreference } from "hooks/usePreference";
import { PreferenceType } from "providers/types";

export interface RelevantExperienceProps {
  title?: string;
  isVisible?: boolean;
  expanded?: boolean;
  interaction?: Interaction;
}

export const ExecutivePartnership = ({
  title = "Executive Partnership",
  isVisible = true,
  expanded,
}: RelevantExperienceProps) => {
  const { container } = useExecutivePartnershipStyles();
  const { interaction, projectToken } = useFlyoutContext();
  const { preference, updatePreference } = usePreference(PreferenceType.PARTNERSHIP_EMAIL_SENT, {
    token: projectToken,
  });

  if (!isVisible || !interaction?.id) return null;

  const items = [
    "Detailed management mapping",
    "Up to ten expert calls for a flat fee",
    "Discounted direct engagement rates",
  ];

  const sentIds = new Set(preference?.attributes?.sentIds ?? []);
  const hasBeenSent = sentIds.has(interaction.id);

  const handleSendEmail = async () => {
    try {
      await projects.sendPartnershipRequestEmail(projectToken, interaction.id);
      const updatedSentIds = preference?.attributes?.sentIds ?? [];

      if (!updatedSentIds.includes(interaction.id)) {
        await updatePreference({ sentIds: [...updatedSentIds, interaction.id] });
      }
    } catch (error) {
      console.error("Failed to send partnership email", error);
    }
  };

  return (
    <AccordionItem data-testid="executive-partnership-accordion" titleContent={<Title title={title} />} open={expanded}>
      <x.div {...container}>
        <Typography color="strong" component="div">
          Our Executive Partnerships team specialize in engaging executives, with similar profiles to this expert, to
          provide direct diligence support. Collaborate with them for:
        </Typography>
        <BulletPointsList items={items} />
        {hasBeenSent ? (
          <Alert
            w="auto"
            title="Explore Executive Partnerships"
            variant="info"
            data-testid="executive-partnership-alert"
          >
            The EP team will be in touch shortly.
          </Alert>
        ) : (
          <x.div>
            <Button size="small" variant="outline" onClick={handleSendEmail} data-testid="send-email-button">
              <Typography variant="body-em" whiteSpace="nowrap">
                Explore Executive Partnerships
              </Typography>
            </Button>
          </x.div>
        )}
      </x.div>
    </AccordionItem>
  );
};
const BulletPointsList = ({ items }: { items: string[] }) => {
  const { list } = useExecutivePartnershipStyles();
  return (
    <x.ul {...list} data-testid="executive-partnership-list">
      {items.map((item) => (
        <x.li key={item}>
          <Typography color="strong" variant="body">
            {item}
          </Typography>
        </x.li>
      ))}
    </x.ul>
  );
};

const Title = ({ title }: { title: string }) => <Typography variant="body-large-em">{title}</Typography>;

const useExecutivePartnershipStyles = () => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: inner.base06,
    },
    list: {
      listStyleType: "disc",
      marginLeft: layout.base03,
    },
  };
};
