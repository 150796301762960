import { useMemo } from "react";
import _ from "lodash";
import { useThirdPartyDocuments } from "./useThirdPartyDocuments";
import { ThirdPartyInteraction } from "types";

export interface ExpertCountByAngle {
  name: string;
  count: number;
  subAngles?: {
    name: string;
    count: number;
  }[];
}

export interface AngleTypeData {
  angleType: string;
  transcriptIds: string[];
  expertCountByAngle: ExpertCountByAngle[];
}

export const useSynthesisExpertCount = (interactions: Interaction[]): AngleTypeData[] => {
  const thirdPartyDocuments = useThirdPartyDocuments();

  const angleTypeData = useMemo(() => {
    const interactionsWithTranscripts = interactions.filter(
      (i) =>
        (i.recordings ?? [])
          .flatMap((r: Recording) => r.transcriptRequests ?? [])
          .filter((tr) => !tr.purgedAt && tr.completed).length > 0
    );

    const tpdAngleTypes = _.compact(thirdPartyDocuments.flatMap((i) => i.attribute.experts.map((e) => e.angle)));
    const interactionAngleTypes = _.compact(
      _.uniq(interactionsWithTranscripts.map((i) => i.group).map((g) => g.angleTypeName))
    );
    const angleTypes = _.uniq([...tpdAngleTypes, ...interactionAngleTypes]);

    const map = angleTypes.map((angleType) => ({
      angleType,
      expertCountByAngle: computeExpertCountByAngleName(
        interactionsWithTranscripts.filter((i) => i.group.angleTypeName === angleType),
        thirdPartyDocuments.filter((i) => i.attribute.experts.some((e) => e.angle === angleType))
      ),
      transcriptIds: computeTranscriptIdsByAngleType(interactionsWithTranscripts, angleType),
    }));
    return map;
  }, [interactions, thirdPartyDocuments]);

  return angleTypeData;
};

const countExpertsByAngleName = (
  interactions: Interaction[],
  thirdPartyDocuments: ThirdPartyInteraction[],
  angleName: string
) => {
  const expertsFromInteractions = _.uniqBy(
    interactions.filter((i) => i.group.name === angleName),
    (i) => i.advisorId
  ).length;

  const expertsFromThirdPartyDocuments = thirdPartyDocuments
    .flatMap((i) => i.attribute.experts)
    .filter((e) => e.angle === angleName).length;

  return expertsFromInteractions + expertsFromThirdPartyDocuments;
};

const computeExpertCountByAngleName = (interactions: Interaction[], thirdPartyDocuments: ThirdPartyInteraction[]) => {
  const angles = _.uniqBy(
    [
      ...interactions
        .map((i) => i.group)
        .map((g) => ({ angleName: g.parent?.name ?? g.name, subAngleName: g.parent ? g.name : null })),
      ...thirdPartyDocuments
        .flatMap((i) => i.attribute.experts)
        .map((e) => ({ angleName: e.angle!, subAngleName: null })),
    ],
    (el) => el.angleName + el.subAngleName
  );

  const groupedAngles = _.groupBy(angles, (a) => a.angleName);
  return _.values(groupedAngles).map((v) => {
    const angleName = v[0].angleName;
    return {
      name: angleName,
      count: countExpertsByAngleName(interactions, thirdPartyDocuments, angleName),
      subAngles: v
        .filter((a) => a.subAngleName)
        .map((a) => ({
          name: a.subAngleName!,
          count: countExpertsByAngleName(interactions, thirdPartyDocuments, a.subAngleName!),
        })),
    };
  });
};

const computeTranscriptIdsByAngleType = (interactions: Interaction[], angleType: string) => {
  const relevantInteractions = interactions.filter((i) => i.group.angleTypeName === angleType);
  return _.uniq(
    relevantInteractions.flatMap((interaction) =>
      interaction.recordings.flatMap((r) => r.transcriptRequests.map((tr) => tr.id))
    )
  );
};
