import { useEffect, useState } from "react";
import { ProjectSynthesisProvider, useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { x } from "@xstyled/styled-components";
import { SynthesisModuleHeader } from "./SynthesisModuleHeader";
import { useStyles } from "./ProjectSynthesisView.styles";
import { SynthesisSidebarHeader } from "./SynthesisSidebarHeader";
import { SynthesisModuleContent } from "./SynthesisModuleContent";
import { SynthesisSidebarContent } from "./SynthesisSidebarContent";
import { Skeleton } from "@alphasights/alphadesign-components";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import useQueryParams from "hooks/useQueryParams";
import { useSynthesisExpertCount } from "./hooks/useSynthesisExpertCount";

const POLLING_INTERVAL = 30 * 1000;

export interface ProjectSynthesisViewProps {
  project: Project;
  pollingTimeout: number;
}

export const ProjectSynthesisView = ({ project, pollingTimeout = POLLING_INTERVAL }: ProjectSynthesisViewProps) => {
  const { allInteractionsForProject, allInteractionsForProjectIsNull: loading } = useProjectInteractionsContext() as {
    allInteractionsForProject: Interaction[];
    allInteractionsForProjectIsNull: boolean;
  };
  const user = useCurrentUser();

  const angleTypeData = useSynthesisExpertCount(allInteractionsForProject);

  if (loading) {
    return <LoadingBars />;
  }

  return (
    <ProjectSynthesisProvider project={project} angleTypes={angleTypeData} user={user} pollingTimeout={pollingTimeout}>
      <ProjectSynthesisViewNoCtx />
    </ProjectSynthesisProvider>
  );
};

const ProjectSynthesisViewNoCtx = () => {
  const styles = useStyles();
  const queryParams = useQueryParams();
  const { revision, selectedModule } = useProjectSynthesisContext();

  useEffect(
    function pushSelectedModuleToUrl() {
      if (!selectedModule) return;
      selectedModule.id
        ? queryParams.set("selectedQuestion", selectedModule?.id)
        : queryParams.delete("selectedQuestion");
      revision?.id ? queryParams.set("selectedRevision", revision?.revision) : queryParams.delete("selectedRevision");
    },
    [queryParams, revision, selectedModule]
  );

  useEffect(function saveScrollOnDestroy() {
    return () => {
      const scrollToSave = document.querySelector("[data-synthesis-scrollable-area]")?.scrollTop ?? 0;
      sessionStorage.setItem("synthesisPosition", scrollToSave.toString());
    };
  }, []);

  return (
    <x.div {...styles.viewWrapper}>
      <SynthesisSidebarWrapper />
      <SynthesisModuleWrapper />
    </x.div>
  );
};

const SynthesisSidebarWrapper = () => {
  const styles = useStyles();
  const [showSuggested, setShowSuggested] = useState(true);
  const { loading } = useProjectSynthesisContext();

  return (
    <x.div {...styles.sidebarWrapper}>
      {loading ? (
        <LoadingBars />
      ) : (
        <>
          <SynthesisSidebarHeader showSuggested={showSuggested} setShowSuggested={setShowSuggested} />
          <SynthesisSidebarContent showSuggested={showSuggested} />
        </>
      )}
    </x.div>
  );
};

const SynthesisModuleWrapper = () => {
  const styles = useStyles();

  const { loading } = useProjectSynthesisContext();

  return (
    <x.div {...styles.moduleWrapper}>
      {loading ? (
        <LoadingBars />
      ) : (
        <>
          <SynthesisModuleHeader />
          <SynthesisModuleContent />
        </>
      )}
    </x.div>
  );
};

const LoadingBars = () => {
  const styles = useStyles();
  return (
    <x.div {...styles.loadingBars}>
      {[...Array(10)].map((_, idx) => (
        <Skeleton key={idx} variant="noMargin" width="100%" height="50px" />
      ))}
    </x.div>
  );
};
