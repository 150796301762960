import { Icon } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";
import { useRelevantCompanyLogo } from "app/services/useRelevantCompanyLogo";
import { useFlags } from "app/wrappers/FeatureFlagsWrapper";
import { Skeleton } from "@alphasights/alphadesign-components";
import styled from "styled-components";
import { th } from "@xstyled/styled-components";
import { useIsConsultingClientProject } from "app/services/useProject";

const RelevantCompanyLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  min-width: 32px;

  border-radius: ${th.radius("8")};
  border: ${th.borderWidth("sm")} solid ${th.color("border-disabled")};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Loading = () => (
  <div>
    <Skeleton circle />
  </div>
);

export type RelevantCompanyLogoProps = {
  projectToken: string;
  interactionId: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const RelevantCompanyLogo = ({ projectToken, interactionId, ...props }: RelevantCompanyLogoProps) => {
  const { enableCdsLogos } = useFlags();
  const isConsultingClient = useIsConsultingClientProject(projectToken);
  const logoQuery = useRelevantCompanyLogo(projectToken, interactionId, enableCdsLogos);

  if (!enableCdsLogos) {
    return null;
  }

  if (isConsultingClient) {
    return null;
  }

  const { data, isLoading } = logoQuery;

  return (
    <RelevantCompanyLogoContainer {...props}>
      {isLoading && <Loading />}
      {!isLoading && data && <img src={data} alt="Company logo" />}
      {!isLoading && !data && (
        <Icon size="large">
          <Company />
        </Icon>
      )}
    </RelevantCompanyLogoContainer>
  );
};
