import { useQuery } from "query-utils";
import { projects as projectsApi, Project } from "@alphasights/portal-api-client";

export const GET_PROJECT_DATA_KEY = "GET_PROJECT_DATA_KEY";

export function useProject(projectToken: string) {
  return useQuery<Project, Project, { status: number; jsonBody?: { project?: string }; message: string }>(
    [GET_PROJECT_DATA_KEY, projectToken],
    () => projectsApi.getProject(projectToken),
    {
      enabled: !!projectToken,
      refetchOnMount: false,
      cacheTime: 0,
    }
  );
}

export function useIsConsultingClientProject(projectToken: string) {
  const { data: project } = useProject(projectToken);
  return project?.isConsultingClient ?? false;
}
