export const getQuoteHtml = (text: string, speaker: string) => {
  if (!text) {
    return "";
  }
  return `
    <div>
      <em>${text}</em>
      <br/><br/>
      <strong style="display: block; text-align: right;">${speaker}</strong>
    </div>
  `;
};

export const copyQuoteToClipboard = async (text: string, speakerCompany: string, speakerJobTitle: string) => {
  const speakerText = speakerCompany && speakerJobTitle ? `${speakerCompany} - ${speakerJobTitle}` : "Interviewer";

  const sanitisedText = `"${text.replace(/\s+/g, " ").trim()}"`;
  const quoteInPlainText = `${sanitisedText}\n\n${speakerText}`;
  const quoteHtml = getQuoteHtml(sanitisedText, speakerText);

  const clipboardItem = new ClipboardItem({
    "text/html": new Blob([quoteHtml], { type: "text/html" }),
    "text/plain": new Blob([quoteInPlainText], { type: "text/plain" }),
  });

  await navigator.clipboard.write([clipboardItem]);
};
