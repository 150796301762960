import { Button, Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBulletPoint = styled(x.ul)(() => {
  const { font, spacing } = useThemeTokens();
  return {
    listStyleType: "disc",
    fontSize: font.size["01"],
    margin: `${spacing.inner.base03} 0 ${spacing.inner.base02} ${spacing.inner.base04}`,
  };
});

export const StyledDivider = styled(Divider)(() => {
  const { spacing } = useThemeTokens();
  return {
    marginTop: spacing.inner.base06,
    marginBottom: spacing.inner.base06,
    marginLeft: 0,
    marginRight: 0,
  };
});

export const StyledPoint = styled(Typography)(() => {
  const { spacing } = useThemeTokens();
  return {
    paddingBottom: spacing.inner.base,
  };
});

export const StyledQuote = styled(Typography)(() => {
  const { color, spacing, shape } = useThemeTokens();
  return {
    borderLeft: `${shape.border.width.large} solid ${color.border.successSubtle}`,
    marginLeft: spacing.inner.base02,
    marginBottom: spacing.inner.base04,
    fontStyle: "italic",
    paddingLeft: spacing.inner.base02,
    backgroundColor: color.background.surface.page.default,
    "&:hover": {
      backgroundColor: color.background.surface.page.hover,
      transition: "background-color 0.2s ease-in-out",
    },
  };
});

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)(() => {
  const { spacing } = useThemeTokens();
  return {
    marginBottom: spacing.inner.base02,
  };
});

export const ActionGroupContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  #quote-container:hover > &,
  #quote-container[data-hover="true"] > & {
    opacity: 1;
  }
`;
