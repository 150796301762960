import { FC, useCallback } from "react";
import ReactHtmlParser from "react-html-parser";
import { ActionGroup, IconGroup, Typography, useAlphaToast } from "@alphasights/alphadesign-components";
import { ChevronRight, Copy } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

import CopyQuoteButton from "components/CopyQuoteButton";
import {
  COPY_QUOTE_SUCCESS_MESSAGE,
  COPY_QUOTE_ERROR_MESSAGE,
  COPY_QUOTE,
} from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView/consts";
import useKeywordSearch from "hooks/useKeywordSearch";
import { viewInTranscriptText } from "content/AlphaNow";
import { copyQuoteToClipboard, copyThemeToClipboard } from "./utils";
import { COPY_BUTTON_STYLES } from "./consts";

import * as S from "./SummaryTheme.styled";

export const DataTestIds = {
  COPY_ICON: "copy-icon",
  COPY_THEME_BUTTON: "copy-theme-button",
  VIEW_IN_TRANSCRIPT_BUTTON: "view-in-transcript-button",
  ACTION_GROUP_CONTAINER: "action-group-container",
};

const COPY_ACTION_DEF = {
  icon: <Copy data-testid={DataTestIds.COPY_ICON} />,
  tooltipTitle: COPY_QUOTE,
};

const VIEW_IN_TRANSCRIPT_ACTION_DEF = {
  icon: <ChevronRight data-testid={DataTestIds.VIEW_IN_TRANSCRIPT_BUTTON} />,
  tooltipTitle: viewInTranscriptText,
};

type SummaryThemeProps = {
  data: {
    theme: string;
    points: string[];
    quotes: { quote: string; fragmentId: number }[];
  };
  selectedIndex: number;
  onClick: (index: number) => void;
};

const SummaryTheme: FC<SummaryThemeProps> = ({ data: { theme, points, quotes }, selectedIndex, onClick }) => {
  const { toast } = useAlphaToast();
  const { addRefToKeyword } = useKeywordSearch(selectedIndex);

  const handleCopyTheme = useCallback(async () => {
    try {
      await copyThemeToClipboard(
        theme,
        points,
        quotes.map((data) => data.quote)
      );
      toast.success({ message: COPY_QUOTE_SUCCESS_MESSAGE });
    } catch (err) {
      console.error(COPY_QUOTE_ERROR_MESSAGE, err);
    }
  }, [theme, points, quotes, toast]);

  const handleCopyQuote = useCallback(
    async (quoteText: string) => {
      try {
        await copyQuoteToClipboard(quoteText);
        toast.success({ message: COPY_QUOTE_SUCCESS_MESSAGE });
      } catch (err) {
        console.error(COPY_QUOTE_ERROR_MESSAGE, err);
      }
    },
    [toast]
  );

  return (
    <>
      <>
        <div id="copy-theme-hover-area">
          <S.TitleContainer>
            <Typography variant="body-em">{theme && ReactHtmlParser(theme, { transform: addRefToKeyword })}</Typography>
            <CopyQuoteButton
              onClick={() => handleCopyTheme()}
              styles={COPY_BUTTON_STYLES}
              testId={DataTestIds.COPY_THEME_BUTTON}
            />
          </S.TitleContainer>
          <S.StyledBulletPoint>
            {points?.map((point: string) => (
              <li key={point.toString()}>
                <S.StyledPoint variant="body">
                  {points &&
                    ReactHtmlParser(point, {
                      transform: addRefToKeyword,
                    })}
                </S.StyledPoint>
              </li>
            ))}
          </S.StyledBulletPoint>
        </div>
        <ul>
          {quotes?.map(({ quote, fragmentId }, index) => {
            const actionGroupDef: IconGroup[] = [
              {
                ...COPY_ACTION_DEF,
                onClick: () => handleCopyQuote(quote),
              },
              {
                ...VIEW_IN_TRANSCRIPT_ACTION_DEF,
                onClick: () => onClick(fragmentId),
              },
            ];
            return (
              <x.li key={index} position="relative" id="quote-container">
                <S.StyledQuote>
                  {quotes &&
                    ReactHtmlParser(`"${quote}"`, {
                      transform: addRefToKeyword,
                    })}
                </S.StyledQuote>
                <S.ActionGroupContainer data-testid={DataTestIds.ACTION_GROUP_CONTAINER}>
                  <ActionGroup iconGroup={actionGroupDef} />
                </S.ActionGroupContainer>
              </x.li>
            );
          })}
        </ul>
      </>
      <S.StyledDivider />
    </>
  );
};

export default SummaryTheme;
