export const getQuoteHtml = (quote: string) => {
  if (!quote) {
    return "";
  }
  return `<em>"${quote}"</em>`;
};

export const getThemeHtml = (theme: string, points: string[], quotes: string[]) => {
  return `
    <div>
      <strong>${theme}</strong>
      <ul>
        ${points.map((point) => `<li>${point}</li>`).join("")}
      </ul>
      ${quotes.map((quote) => getQuoteHtml(quote)).join("")}
    </div>
  `;
};

export const getThemeInPlainText = (theme: string, points: string[], quotes: string[]) => {
  return `
    ${theme}\n
    ${points.map((point) => `- ${point}\n`).join("")}
    ${quotes.map((quote) => `"${quote}"\n\n`).join("")}
  `;
};

export const copyThemeToClipboard = async (theme: string, points: string[], quotes: string[]) => {
  const html = getThemeHtml(theme, points, quotes);
  const plainText = getThemeInPlainText(theme, points, quotes);
  const clipboardItem = new ClipboardItem({
    "text/html": new Blob([html], { type: "text/html" }),
    "text/plain": new Blob([plainText], { type: "text/plain" }),
  });
  await navigator.clipboard.write([clipboardItem]);
};

export const copyQuoteToClipboard = async (quote: string) => {
  const html = getQuoteHtml(quote);
  const clipboardItem = new ClipboardItem({
    "text/html": new Blob([html], { type: "text/html" }),
    "text/plain": new Blob([quote], { type: "text/plain" }),
  });
  await navigator.clipboard.write([clipboardItem]);
};
