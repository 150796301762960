import { FC, useRef, useEffect, useCallback } from "react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

import { logTimeSpent, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import {
  errorLoadingComprehensiveSummaryView,
  comprehensiveSummaryViewUnavailableTryAgainContent,
  generatedByAITag,
} from "content/AlphaNow";
import { View } from "models/Header";
import { useAppSearchContext } from "providers/AppSearchProvider";
import ResearchContainer from "components/ResearchContainer";
import { HEADER } from "./constants";
import SummaryTheme from "./SummaryTheme";

import * as S from "./ComprehensiveSummaryView.styled";

interface ComprehensiveSummaryViewProps {
  contentId: string;
  comprehensiveSummaryFetched: boolean;
  comprehensiveSummaryViewData: any[];
  selectedIndex: any;
  setViewMode: (value: string) => void;
}

const ComprehensiveSummaryView: FC<ComprehensiveSummaryViewProps> = ({
  contentId,
  comprehensiveSummaryFetched,
  comprehensiveSummaryViewData,
  selectedIndex,
  setViewMode,
}) => {
  const { updateQuery } = useAppSearchContext();
  const { logHit } = useTrackUserAction();
  const maxScrollPercentage = useRef(0);

  const handleClickViewInTranscript = useCallback(
    (fragmentId: any) => {
      updateQuery({ fragmentIds: [fragmentId] });
      setViewMode(View.Transcript);

      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowViewQuote,
        details: { contentId, fragmentPosition: fragmentId },
      });
    },
    [updateQuery, setViewMode, contentId, logHit]
  );

  useEffect(() => {
    const startTime = Date.now();
    const scrollPercentage = maxScrollPercentage.current;

    const timeSpentAction = HitAction.alphaNowTimeSpentOnComprehensiveSummary;
    const scrollAction = HitAction.alphaNowComprehensiveSummaryScrollPercentage;

    return () => {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenComprehensiveSummaryView,
        details: { contentId },
      });

      logTimeSpent(startTime, { contentId, userId: "" }, logHit, timeSpentAction);

      logScrollPercentage(
        logHit,
        {
          contentId,
          scrollPercentage,
          userId: "",
        },
        scrollAction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!comprehensiveSummaryFetched) {
    return (
      <ErrorMessage
        header={errorLoadingComprehensiveSummaryView}
        body={comprehensiveSummaryViewUnavailableTryAgainContent}
      />
    );
  }
  return (
    <ResearchContainer header={HEADER} secondaryLabel={generatedByAITag}>
      <S.SummaryContainer className="mentions-container">
        {comprehensiveSummaryViewData.map((data) => (
          <SummaryTheme data={data} selectedIndex={selectedIndex} onClick={handleClickViewInTranscript} />
        ))}
      </S.SummaryContainer>
    </ResearchContainer>
  );
};

export default ComprehensiveSummaryView;
export type { ComprehensiveSummaryViewProps };
