import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { Badge } from "models/Badge";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useQuery } from "query-utils";
import { ThirdPartyDocument, ThirdPartyInteraction } from "types";
import { createThirdPartyInteraction } from "views/DeliverablesView/helpers";
import thirdPartyDocumentsService from "services/thirdPartyDocumentsService";

export const useThirdPartyDocuments = () => {
  const { project } = useCurrentProjectContext();
  const { hasUserBadge } = useUserBadgeContext();
  const { isMobile } = useCheckScreen();

  const currentUser = useCurrentUser() as (User & { documentUploadsEnabled: boolean }) | undefined;
  const hasThirdPartyDocumentsSetting = currentUser?.documentUploadsEnabled;
  const hasThirdPartyDocumentsBadge = hasUserBadge(Badge.thirdPartyDocUpload);
  const shouldShowThirdPartyDocuments = hasThirdPartyDocumentsBadge && !isMobile && hasThirdPartyDocumentsSetting;

  const { data: thirdPartyDocuments = [] } = useQuery<ThirdPartyDocument[], ThirdPartyInteraction[]>(
    ["fetchThirdPartyDocuments"],
    () =>
      thirdPartyDocumentsService
        .fetchDocuments(project!.token)
        .then((tpds) => tpds.filter((tpd) => tpd.documentType === "transcript")),
    {
      select: (data) => data.map(createThirdPartyInteraction),
      enabled: shouldShowThirdPartyDocuments,
      refetchOnMount: false,
    }
  );

  return thirdPartyDocuments;
};
