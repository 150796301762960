import { SPEAKER_ENGLISH_TRANSLATIONS } from "constants/AlphaNow";

export const getSpeakerInfo = (
  fragment: { participant: string; speakerDummyName?: string },
  speakerMap: Record<string, any>
) => {
  const { participant, speakerDummyName } = fragment;
  return {
    ...(speakerMap[participant] ||
      speakerMap[SPEAKER_ENGLISH_TRANSLATIONS[participant as keyof typeof SPEAKER_ENGLISH_TRANSLATIONS]]),
    speakerDummyName,
  };
};

export const getSelectedTextInElement = (element: HTMLElement) => {
  const selection = window.getSelection();

  if (!selection?.rangeCount) return ""; // No selection

  const range = selection.getRangeAt(0); // Get the first range
  if (!element?.contains(range.commonAncestorContainer)) return ""; // Ensure selection is inside the element

  return selection.toString();
};

export const getTextSelectionCoordinates = () => {
  const selection = window.getSelection();
  const range = selection?.getRangeAt(0);
  const rects = range?.getClientRects();
  if (rects?.length === 0) {
    return {};
  }
  const lastRect = rects?.[rects.length - 1];
  return {
    top: lastRect?.bottom ?? 0,
    left: lastRect?.right ?? 0,
  };
};
