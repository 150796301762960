import React, { FC, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
// @ts-ignore TS does not recognize showdown as a module for some reason
import showdown from "showdown";
import { x } from "@xstyled/styled-components";
import {
  Tooltip,
  TooltipContent,
  Typography,
  useThemeTokens,
  IconButton,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { CONTENT_TYPE } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { makeParagraphs } from "pages/AlphaNowPage/utils";
import SpeakerIcon from "pages/AlphaNowPage/components/AlphaNowTranscript/SpeakerIcon";
import CopyQuoteButton from "components/CopyQuoteButton";
import { copyQuoteToClipboard } from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView/utils";
import {
  COPY_QUOTE_SUCCESS_MESSAGE,
  COPY_QUOTE_ERROR_MESSAGE,
} from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView/consts";

import * as S from "./ConversationFragment.styled";

export type ConversationFragmentProps = {
  addRefToMention: (ref: React.RefObject<HTMLSpanElement>) => void;
  speakerInfo: {
    company: string;
    jobTitle: string;
    transcriptParticipantRole: string;
    isModerator: boolean;
    speakerDummyName: string;
  };
  text: string;
  contentType: string;
  isFragmentHighlighted?: boolean;
  shouldHideCopyButton?: boolean;
};

const ConversationFragment: FC<ConversationFragmentProps> = ({
  addRefToMention,
  speakerInfo,
  text,
  contentType,
  isFragmentHighlighted = false,
  shouldHideCopyButton = false,
}) => {
  const { color, spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const { toast } = useAlphaToast();

  const fragmentTextRef = useRef(null);

  const { isModerator, transcriptParticipantRole, company, jobTitle, speakerDummyName } = speakerInfo;

  const converter = new showdown.Converter();
  const paragraphs = makeParagraphs(text);
  const parsedText = converter.makeHtml(paragraphs);
  const isPrivate = contentType === CONTENT_TYPE.privateEnterprise;
  const speakerDisplayName = isModerator ? transcriptParticipantRole : `${company} - ${jobTitle}`;
  const hideSpeakerNamePlaceholder = isModerator || isPrivate;

  const handleCopyFragment = async () => {
    try {
      await copyQuoteToClipboard(text, company, jobTitle);
      toast.success({ message: COPY_QUOTE_SUCCESS_MESSAGE });
    } catch (err) {
      console.error(COPY_QUOTE_ERROR_MESSAGE, err);
    }
  };

  const placeholder = hideSpeakerNamePlaceholder ? null : (
    <x.span display="inline-flex" flexDirection="row" color={color.text.secondary} alignItems="center">
      <Typography component="span" variant="body" px={spacing.inner.base}>
        |
      </Typography>
      <Typography component="span" variant="body" data-testid="placeholder-name" pr={spacing.inner.base}>
        {speakerDummyName}
      </Typography>
      {!isMobile && (
        <Tooltip maxWidth={undefined}>
          <TooltipContent>
            <Typography>Placeholder name to retain the expert's anonymity.</Typography>
          </TooltipContent>
          <IconButton size="small" variant="basic" color={color.text.secondary} testId="information-icon">
            <Info />
          </IconButton>
        </Tooltip>
      )}
    </x.span>
  );

  return (
    <S.Wrapper id="conversation-fragment-wrapper" shouldHideCopyButton={shouldHideCopyButton}>
      <S.SpeakerWrapper>
        <SpeakerIcon speaker={speakerInfo} contentType={contentType} isMentionsView={false} />
        <S.SpeakerInfoWrapper>
          <div>
            <Typography component="span" flexWrap="wrap" variant="body-em" color={color.text.strong._}>
              {speakerDisplayName}
            </Typography>
            {placeholder}
          </div>
          {!shouldHideCopyButton && (
            <CopyQuoteButton
              onClick={handleCopyFragment}
              styles={{
                marginLeft: spacing.inner.base04,
                marginRight: `-${spacing.inner.base02}`,
                marginTop: `-${spacing.inner.base02}`,
                opacity: 0,
                transition: "opacity 0.2s ease-in-out",
                // @ts-ignore
                "#conversation-fragment-wrapper:hover &": {
                  opacity: 1,
                },
              }}
            />
          )}
        </S.SpeakerInfoWrapper>
      </S.SpeakerWrapper>
      <Typography
        ref={fragmentTextRef}
        className="mentions-container"
        component="span"
        variant="body"
        transition="all 1s"
        backgroundColor={isFragmentHighlighted ? color.background.surface.page.hover : undefined}
        borderRadius={spacing.inner.base02}
        pl={{ xs: 0, sm: spacing.inner.base12 }}
        pt={{ xs: spacing.inner.base, sm: 0 }}
        color={color.text.strong._}
        lineHeight={spacing.inner.base06}
      >
        {ReactHtmlParser(parsedText, {
          transform: addRefToMention,
        })}
      </Typography>
    </S.Wrapper>
  );
};

export default ConversationFragment;
