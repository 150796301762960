import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ApexOptions } from "apexcharts";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import { CHART_TYPES, ThreeDimensionalScatterChartKpcAggregated } from "views/SurveysResultsView/api/types";

type ChartConfigProps = {
  results: ThreeDimensionalScatterChartKpcAggregated[];
  selectedChartSegment: number;
  setSelectedChartSegment: (index: number) => void;
  chartType: CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC | CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT;
};

const getValues = (results: ThreeDimensionalScatterChartKpcAggregated[]) =>
  results.map((result) => ({
    name: result.x.answerText,
    data: result.yElements.map((yElement) => ({
      x: yElement.y.answerText,
      y: yElement.average?.value || 0,
    })),
  }));

const getLabels = (results: ThreeDimensionalScatterChartKpcAggregated[]) => {
  return {
    xAxisLabels: results[0]?.yElements.map((y) => y.y.answerText) || [],
    yAxisLabels: results[0]?.yElements[0]?.zElements.map((z) => z.answerText) || [],
  };
};

export const useChartConfig = ({
  results,
  selectedChartSegment,
  setSelectedChartSegment,
  chartType,
}: ChartConfigProps) => {
  const { color, typography } = useThemeTokens();
  const { getChartColors } = useGetChartColors({
    numberOfBars: results.length,
  });

  const values = getValues(results);
  const { xAxisLabels, yAxisLabels } = getLabels(results);

  return {
    vendors: results[selectedChartSegment].yElements[0].y.quantity,
    values,
    config: {
      chart: {
        id: "KPCCompetitiveSurveyGeneric",
        toolbar: { show: false },
        fontFamily: typography.body.regular.fontFamily,
        zoom: { enabled: false },
        parentHeightOffset: 0,
        events: {
          markerClick: function (
            event: any,
            chartContext: any,
            { seriesIndex, dataPointIndex, config }: { seriesIndex: any; dataPointIndex: any; config: any }
          ) {
            setSelectedChartSegment(seriesIndex);
          },
        },
      },
      stroke: { width: 2 },
      xaxis: {
        categories: xAxisLabels,
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: {
          show: true,
          style: {
            colors: [`${color.text.strong._}`],
            ...typography.body.small,
            fontSize: "12px",
          },
        },
        tooltip: { enabled: false },
        crosshairs: { show: false },
      },
      yaxis: {
        min: 1,
        max: 5,
        forceNiceScale: false,
        labels: {
          style: {
            colors: [`${color.text.strong._}`],
            ...typography.body.small,
            fontSize: "12px",
          },
          align: "left",
          formatter: (value: number) => {
            if (chartType === CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC) {
              switch (value) {
                case 1:
                  return "Very Weak";
                case 2:
                  return "Weak";
                case 3:
                  return "Neutral";
                case 4:
                  return "Strong";
                case 5:
                  return "Very Strong";
                default:
                  return value;
              }
            }
            return yAxisLabels.includes(value.toString()) ? value : "";
          },
        },
        axisBorder: { show: false },
        tickAmount: 4,
      },
      colors: getChartColors(selectedChartSegment),
      markers: {
        size: 5,
        strokeWidth: 0,
        hover: { sizeOffset: 0 },
      },
      grid: {
        show: true,
        padding: { left: 30, right: 30 },
      },
      legend: { show: false },
      tooltip: {
        custom: () => "",
      },
    } as ApexOptions,
  };
};
