import { Popover, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const ConversationWrapper = styled(x.div)(() => {
  const { spacing, color, shape } = useThemeTokens();
  return {
    mb: spacing.layout.base03,
    ".ads-popover:has(#copy-selection-button)": {
      height: spacing.inner.base08,
      width: spacing.inner.base08,
      backgroundColor: color.background.surface.page.default,
      borderRadius: shape.border.radius.medium,
    },
    ".ads-popover .body-content:has(#copy-selection-button)": {
      display: "flex",
      alignItems: "center",
      width: spacing.inner.base08,
      height: spacing.inner.base08,
    },
  };
});

export const StyledCopySelectionButtonPopover = styled(Popover)(({ isVisible }: { isVisible: boolean }) => {
  const { spacing, shape } = useThemeTokens();
  return {
    zIndex: 10,
    opacity: isVisible ? 1 : 0,
    transition: "opacity 0.2s ease-in-out",
    display: isVisible ? "block" : "none",
    height: spacing.inner.base08,
    width: spacing.inner.base08,
    borderRadius: shape.border.radius.medium,
  };
});
